import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Giveaway from "../components/Forms/Giveaway";

export default class GiveAwayPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Giveaway" />
        <Giveaway />
      </Fragment>
    );
  }
}
