import React, { PureComponent } from "react";
import cx from "classnames";
import { navigate } from "gatsby";

import Navbar from "../../Navbar";
import Footer from "../../Footer";
import Terms from "../../Terms";

import styles from "../Common.module.scss";
import formStyles from "../Form.module.scss";

import GiveawayImg from "../../../img/giveaway.png";
import { FaTimesCircle } from "react-icons/fa";

export default class Giveaway extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showTermsModal: false,
      acceptedTerms: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
      const loaderElem = document.getElementById("loader");
      if (loaderElem) {
        loaderElem.parentNode.removeChild(loaderElem);
      }
    }, 0);
  }

  resetValidation = e => {
    e.target.setCustomValidity("");
  };

  onSubmit = e => {
    if (this.state.acceptedTerms) {
      return true;
    }
    e.preventDefault();
    alert("You must accept the terms and conditions.");
    return false;
  };

  render() {
    const { loaded, showTermsModal } = this.state;
    return (
      <div className={cx(styles.FormPage, { [styles.Loaded]: loaded })}>
        <Navbar
          goTo={page => {
            navigate(`/#${page}`);
          }}
          pathname="giveaway"
        />
        <div className={cx(styles.Page, { [styles.Active]: loaded })}>
          <div>
            <div className={styles.Content}>
              <div>
                <img src={GiveawayImg} />
                <div className={styles.BackgroundOverlay} />
              </div>
              <div className={styles.ContentText}>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ maxWidth: "250px" }}>
                    <h3 style={{ margin: 0, whiteSpace: "nowrap" }}>
                      Casi Ari is giving away
                    </h3>
                    <h3 style={{ margin: 0, whiteSpace: "nowrap" }}>
                      a brand build in 2020.
                    </h3>
                    <br />
                    <div>
                      <p>
                        Our mission is to seek out brands who are doing good for
                        humanity and enable them to do so. Unfortunately, not
                        every deserving organization has the budget to put their
                        best foot forward when building their brand and crafting
                        their message. That’s why we’ve decided to give away an
                        entire brand build to an organization that needs our
                        help.
                      </p>
                      <p>
                        Please review{" "}
                        <a
                          style={{
                            cursor: "pointer",
                            borderBottom: "1px solid grey"
                          }}
                          onClick={() =>
                            this.setState({ showTermsModal: true })
                          }
                        >
                          the conditions
                        </a>{" "}
                        and use this form to nominate a business.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.MiniContent}>
                    <h2
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontSize: "1.5em"
                      }}
                    >
                      Casi Ari is giving away a brand build in 2020.
                    </h2>
                  </div>
                  <form
                    name="giveaway"
                    className={formStyles.Form}
                    method="POST"
                    netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-netlify="true"
                    onSubmit={this.onSubmit}
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="giveaway" />
                    <div className={formStyles.FormFieldsWrapper}>
                      <input
                        required
                        onInvalid={e =>
                          e.target.setCustomValidity("Please enter your name.")
                        }
                        onInput={this.resetValidation}
                        name="name"
                        placeholder="My name is:"
                      />
                      <input
                        required
                        onInvalid={e =>
                          e.target.setCustomValidity(
                            "Please enter a valid email."
                          )
                        }
                        onInput={this.resetValidation}
                        name="email"
                        placeholder="My email is:"
                        type="email"
                      />
                    </div>

                    <div className={formStyles.FormFieldsWrapper}>
                      <input
                        // required
                        // onInvalid={e =>
                        //   e.target.setCustomValidity(
                        //     "Please enter an organization contact."
                        //   )
                        // }
                        // onInput={this.resetValidation}
                        name="organization-contact"
                        placeholder="Organization contact:"
                      />
                      <input
                        // required
                        // onInvalid={e =>
                        //   e.target.setCustomValidity(
                        //     "Please enter a valid organization email."
                        //   )
                        // }
                        // onInput={this.resetValidation}
                        name="organization-email"
                        placeholder="Organization email:"
                        type="email"
                      />
                    </div>
                    <div className={formStyles.FormFieldsWrapper}>
                      <textarea
                        // required
                        // onInvalid={e =>
                        //   e.target.setCustomValidity("Please enter the details.")
                        // }
                        // onInput={this.resetValidation}
                        name="details"
                        rows={3}
                        placeholder="Organization details:"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className={formStyles.CheckboxGroup}>
                      <label className={formStyles.Checkbox}>
                        This is my organization
                        <input
                          name="my-organization"
                          type="checkbox"
                          className={formStyles.Checked}
                        />
                        <span className={formStyles.Checkmark} />
                      </label>
                      <label className={formStyles.Checkbox}>
                        This is someone else's organization
                        <input
                          name="someones-organization"
                          type="checkbox"
                          className={formStyles.Checked}
                        />
                        <span className={formStyles.Checkmark} />
                      </label>
                    </div>
                    <div
                      className={formStyles.FormFooter}
                      style={{ marginTop: "10px" }}
                    >
                      <label className={formStyles.Checkbox}>
                        I have read the{" "}
                        <span
                          style={{ textDecoration: "underline" }}
                          onClick={e => {
                            e.stopPropagation();
                            this.setState({ showTermsModal: true });
                          }}
                        >
                          terms &amp; conditions
                        </span>
                        <input
                          type="checkbox"
                          className={formStyles.Checked}
                          checked={this.state.acceptedTerms}
                          onClick={() => {
                            this.setState(prevState => ({
                              acceptedTerms: !prevState.acceptedTerms
                            }));
                          }}
                        />
                        <span className={formStyles.Checkmark} />
                      </label>
                      <button>BEGIN</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer
            goTo={page => {
              navigate(`/#${page}`);
            }}
          />
          {showTermsModal && (
            <div className={styles.TermsModal}>
              <div>
                <FaTimesCircle
                  onClick={() => {
                    this.setState({ showTermsModal: false });
                  }}
                />
                <Terms />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
